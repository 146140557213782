import React, { useState } from "react";
import logo from "../img/jg_logo.png";
import { Link } from "react-scroll";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

const Navbar = props => {
  const [scroll, setScroll] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (i18n.isInitialized) {
      setIsLoaded(true);
    }
  }, [i18n.isInitialized]);

  const changeBackground = () => {
    if (window.scrollY >= 64) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    // <nav
    //   className={`py-3 navbar navbar-expand-md navbar-dark ${
    //     scroll ? "navbar-active" : ""
    //   }`}
    // >
    <nav className="py-3 navbar navbar-expand-md navbar-dark navbar-dark">
      <div className="container">
        <a className="navbar-brand" href="#">
          {/* <img src={logo} height={48}></img> */}
          <span className="logo px-2">JG</span>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i
            className="bi bi-list"
            style={{ fontSize: 32, color: "white" }}
          ></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className={`navbar-nav ms-auto ${isLoaded ? "" : "invisible"}`}>
            <li className="nav-item px-2">
              <Link
                className="nav-link"
                activeClass="active"
                to="#Home"
                spy={true}
                offset={-160}
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                {props.home}
              </Link>
            </li>
            <li className="nav-item px-2">
              <Link
                className="nav-link"
                activeClass="active"
                to="#About"
                spy={true}
                offset={-120}
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                {props.about}
              </Link>
            </li>
            <li className="nav-item px-2">
              <Link
                className="nav-link"
                activeClass="active"
                to="#Projects"
                spy={true}
                offset={-120}
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                {props.projects}
              </Link>
            </li>
            <li className="nav-item px-2">
              <Link
                className="nav-link"
                activeClass="active"
                to="#Contact"
                spy={true}
                offset={-120}
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                {props.contact}
              </Link>
            </li>
            <LanguageSwitcher />
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
